.App {
  text-align: center;
}

.header {
  background-color: #023047;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2rem;
  color: white;
}

.header-button {
  background-color: #FB8500 !important;
  border-color: #FB8500 !important;
  width: 10rem;
  margin: 0.5rem;
}

.signed-in {
  font-size: 0.9rem;
  margin: 0.5rem;
}

.sign-out-button {
  background-color: #219EBC !important;
  border-color: #219EBC !important;
  margin: 0.5rem;
}

.card-404 {
  display: flex;
  flex-direction: row;
  margin: 10px;
  justify-content: center;
}

.link-button {
  width: 32rem;
  height: 4rem;
  margin-right: 1rem;
  border-color: #023047;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  background-color: white;
  padding-left: 1rem;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.arrow {
  width: 2.5rem;
  border: none;
  border-radius: 0.3rem;
  background-color: #219EBC;
}

.score-num {
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contribute-modal {
  background-color: red;
}